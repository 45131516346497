module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"wrapperStyle":"padding: 3rem 0; background: #be185d; box-shadow:inset 0 0 15px #000;","disableBgImageOnAlpha":true,"withWebP":{"quality":70},"backgroudColor":"#be185d","tracedSVG":{"color":"#be185d","turnPolicy":"TURNPOLICY_MAJORITY"}},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"custom":[{"name":["Omega Sans","Qavo"],"file":"/fonts/fonts.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chymeia","short_name":"Chymeia","start_url":"/","background_color":"#ffd700","theme_color":"#ffd700","display":"minimal-ui","icon":"src/assets/icons/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c9b3a8ee87702552629e70280a17cbbe"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
